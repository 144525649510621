var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "before-close": () => (_vm.dialogVisible = false),
        "close-on-click-modal": false,
        width: "60%",
        top: "7vh",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("新增学员")])]
      ),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.formInfo,
            rules: _vm.rules,
            inline: false,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formInfo.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInfo, "name", $$v)
                      },
                      expression: "formInfo.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "tel" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formInfo.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInfo, "tel", $$v)
                      },
                      expression: "formInfo.tel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "初始密码", prop: "pwd" } },
                [
                  _c("el-input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.formInfo.pwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInfo, "pwd", $$v)
                      },
                      expression: "formInfo.pwd",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v(" 确 定 ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(" 取 消 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }