<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="() => (dialogVisible = false)"
    :close-on-click-modal="false"
    width="60%"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>新增学员</span>
    </div>
    <el-form
      :model="formInfo"
      :rules="rules"
      :inline="false"
      ref="ruleForm"
      label-width="120px"
    >
      <el-row>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="tel">
          <el-input v-model="formInfo.tel"></el-input>
        </el-form-item>
        <el-form-item label="初始密码" prop="pwd">
          <el-input type="password" v-model="formInfo.pwd"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row slot="footer">
      <el-button
        type="primary"
        @click="submitForm('ruleForm')"
        class="dialog-btn"
      >
        确 定
      </el-button>
      <el-button @click="dialogVisible = false" type="info" class="dialog-btn">
        取 消
      </el-button>
    </el-row>
  </el-dialog>
</template>
<script>
export default {
  data() {
    const validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      formInfo: {
        name: "",
        tel: "",
        pwd: "",
        state: "1",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        pwd: [{ required: true, message: "请输入初始密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submit", {
            ...this.formInfo,
            loginName: this.formInfo.tel,
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    initData() {
      this.resetForm("ruleForm");
      this.formInfo = {
        name: "",
        tel: "",
        pwd: "",
        state: "1",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form .el-form-item {
  margin-bottom: 30px;
}
  /deep/ .el-dialog__body {
    padding-top: 0px;
  }
</style>