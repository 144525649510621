<template>
  <el-drawer
    title="详情"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
  >
    <div style="padding: 0 10px">
      <el-row>
        <el-col class="item-title">用户名：{{ item.name }}</el-col>
        <el-col class="item-title">手机号：{{ item.tel }}</el-col>
        <el-col class="item-title">登录名：{{ item.loginName }}</el-col>
        <el-col class="item-title">状态：{{ item.state == "3" ? "禁用" : "启用" }}</el-col>
      </el-row>
    </div>
  </el-drawer>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      direction: "rtl",
      item: {},
    };
  },
  methods: {
    handleClose() {
      this.drawer = false;
    },
    initData(item) {
      this.item = { ...item };
    },
  },
};
</script>
<style lang="scss" scoped>
.item-title {
  height: 50px;
}
</style>