<template>
  <div>
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="手机号码：">
              <el-input
                clearable
                v-model="searchForm.tel"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="员工姓名：">
              <el-input
                clearable
                v-model="searchForm.name"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="状态：">
              <el-select
                clearable
                v-model="searchForm.state"
                placeholder="状态"
                class="w100"
              >
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <span>
            <el-button @click="resetForm">重置</el-button>
            <el-button type="primary" @click="searchgetList">查询</el-button>
          </span>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card shadow="never" style="overflow-y: scroll; height: 600px">
      <div slot="header" class="flex" style="align-items: center">
        <span>员工数据</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
          :data="tableData"
          style="max-height: calc(100vh - 270px); overflow-y: auto"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '状态'">
                <el-tag :type="statusOptions[scope.row.state]">
                  {{ scope.row.state == 1 ? '启用' : '禁用' }}
                </el-tag>
              </template>
              <template v-else-if="item.label === '手机号'">
                <span v-if="scope.row.loginName">{{scope.row.loginName}}</span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button
                  style="margin:0 5px 0 8px"
                  type="text"
                  @click="openDetailModal(scope.row)"
                  >查看</el-button>

                  <el-popconfirm
                  @onConfirm="confirmStatus(scope.row)"
                  @confirm="confirmStatus(scope.row)"
                  :title="`确定要${
                    scope.row.state == 1 ? '禁用' : '启用'
                  }该数据吗？`"
                >
                  <el-button type="text" slot="reference">
                    {{ scope.row.state == 1 ? "禁用" : '启用'}}
                  </el-button>
                </el-popconfirm>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
    <!-- </el-card> -->
    <AddModal ref="add" title="添加" type="primary" @submit="addMember" />
    <EditModal ref="edit" title="编辑" :item="item" @submit="editMember" />
    <DetailModal ref="detail" title="查看" />
  </div>
</template>
<script>
import {
  getEmployeeList,
  getListAllEmployee,
  addEmployee,
  editEmployee,
  setEnable,
} from "@/api/member/employee";
import AddModal from "@/views/member/AddModal";
import EditModal from "@/views/member/EditModal";
import DetailModal from "@/views/member/DetailModal";
import TextButton from "@/components/TextButton";
// 默认展示列

const baseColSetting = [
  {
    label: "手机号",
    prop: "tel",
    state: true,
    align: "center",
    // sortable: true,
    width: "180",
  },
  {
    label: "姓名",
    prop: "name",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "状态",
    prop: "state",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "创建时间",
    prop: "createTime",
    state: true,
    sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "130",
  },
];
export default {
  components: { AddModal, EditModal, DetailModal, TextButton },
  data() {
    return {
      tableKey: 1,
      item: {},
      title: "",
      size: "default",
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      searchForm: { loginName: null, name: null, tel: null, state: null },
      statusOptions: {
        1: "success",
        3: "danger",
      },
      options: [
        {
          value: "1",
          label: "启用",
        },
        // {
        //   value: '2',
        //   label: '删除',
        // },
        {
          value: "3",
          label: "禁用",
        },
      ],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.getListHandler();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    /**
     * 获取列表
     */
    async getListHandler() {
      let responseData = await getListAllEmployee({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;

      }
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const { userId, state } = item;
      setEnable({
        userId,
        status: state == "1" ? 3 : 1,
      }).then(() => {
        this.getListHandler();
      });
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        loginName: null,
        name: null,
        tel: null,
        state: null,
      };
      this.getListHandler();
    },
    /**
     * 查看
     */
    handleClick(item) {},
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    searchgetList(){
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    /**
     * 打开编辑弹窗
     */
    openEditModal(item) {
      // TODO 添加获取详情
      this.item = item || {};
      this.$nextTick(() => {
        this.$refs.edit.dialogVisible = true;
      });
    },
    /**
     * 打开添加弹窗
     */
    openAddModal() {
      this.$refs.add.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.add.initData();
      });
    },
    /**
     * 打开详情
     */
    openDetailModal(item) {
      this.$refs.detail.initData(item);
      this.$nextTick(() => {
        this.$refs.detail.drawer = true;
      });
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
    /**
     * 添加成员
     */
    async addMember(value) {
      const { name, tel, pwd, loginName, state } = value;
      const response = await addEmployee({ name, tel, pwd, loginName, state });
      if (response.state == "success") {
        // this.pageInfo.pageNum = 1;
        // this.pageInfo.pageSize = 10;
        this.$message.success("添加成功");
        this.getListHandler();
        this.$refs.add.dialogVisible = false;
      } else {
        this.$message.warning("添加失败");
      }
    },
    async editMember(value) {
      const { id, name, tel, pwd, state } = value;
      const response = await editEmployee({
        id,
        name,
        tel,
        pwd,
        state,
      });
      if (response.state == "success") {
        this.$message.success("编辑成功");
        //   this.pageInfo.pageNum = 1;
        //   this.pageInfo.pageSize = 10;
        this.getListHandler();
        this.$refs.edit.dialogVisible = false;
      } else {
        this.$message.warning("编辑失败");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

@import "../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>

